<template>
  <div class="home">
    <div class="title">
      <div class="top-line"></div>
      <div class="center">
        <div class="left">
          <img src="@/assets/images/xiyao-logo.png" alt="" srcset="">
        </div>
        <div class="right">
          <img src="@/assets/images/xiyao.png" alt="" srcset="">
        </div>
      </div>
      <div class="bottom-line"></div>
    </div>
    <div class="community">
      <div class="add">加入社群</div>
      <div class="unlock">解锁更多独家美食</div>
    </div>
    <div class="person">
      <div class="left">
        <img src="@/assets/images/bg-english.png" alt="" srcset="">
      </div>
      <div class="center">
        <img src="@/assets/images/person.png" alt="" srcset="">
      </div>
      <div class="right">
        <img src="@/assets/images/name.png" alt="" srcset="">
      </div>
    </div>
    <div class="share">
      <div class="btn" @click="handleNavigateJD">
        <!-- <a href="https://mall.jd.com/index-16007708.html" class="jd"> -->
        <a href="https://shop137780470.m.youzan.com/v2/feature/QEGKa058pE?kdt_id=137588302&scan=3&from=kdt&shopAutoEnter=1&is_silence_auth=1&sf=wx_sm&is_share=1&share_cmpt=native_wechat&from_uuid=19526783904" class="jd">
          <!-- <img class="jd" src="@/assets/images/jd.png" alt="" srcset=""> -->
          <span class="goto">跳转购买</span>
        </a>
      </div>
      <div class="ewm">
        <img src="@/assets/images/ewm.png" alt="" srcset="">
      </div>
      <div class="text">长按二维码识别</div>
    </div>
    <div class="videos">
      <div class="video" v-for="(item, index) in foodList" :key="item.id">
        <div class="left">
          <div class="food-name">{{ item.name }}</div>
          <div class="food-desc">
            <div class="top">贾冰监制</div>
            <template v-if="index==0">
              <div class="bottom">浓郁番茄，甘熟浓香</div>
            </template>
            <template v-else-if="index==1">
              <div class="bottom">酸爽过瘾，金色靓汤</div>
            </template>
            <template v-else>
              <div class="bottom">肉汁鲜美，清香回甘</div>
            </template>
          </div>
        </div>
        <div class="center">
          <template v-if="index==0">
            <img src="@/assets/images/operate-1.png" alt="" srcset="">
          </template>
          <template v-else-if="index==1">
            <img src="@/assets/images/operate-2.png" alt="" srcset="">
          </template>
          <template v-else>
            <img src="@/assets/images/operate-3.png" alt="" srcset="">
          </template>
        </div>
        <div class="right">
          <VideoPlayer  :sources="[
                                  {
                                    src: `https://xiyao.shop/video/${index+1}.m3u8`,
                                    type: 'application/x-mpegURL',
                                  }
                                ]"
                        class="video-player"
                        :poster="require(`../assets/images/post${index+1}.jpg`)"
                        :control-bar="controlBar"
                        preload="auto"
                        controls
          >
          </VideoPlayer>
       
        </div>
      </div>
    </div>

    <Icp />
    <div class="company">由上海零咔智能科技有限公司提供网页支持</div>
  </div>
</template>

<script setup>
  import { onMounted, ref } from "vue"
  import { fetchVideoList } from "@/service"

  import { VideoPlayer } from "@videojs-player/vue"
  import 'video.js/dist/video-js.css'
  import Icp from "@/components/Icp.vue";


  let foodList = ref()

  onMounted(() => {
    fetchVideoList().then(res => {
      foodList.value = res.data.splice(0, 3)
    })
  })

  const controlBar = {
    volumePanel: false,
    currentTimeDisplay: false,
    durationDisplay: false,
    liveDisplay: false,
    remainingTimeDisplay: false,
    progressControl: false,
    chaptersButton: false,
    customControlSpacer: false,
    subsCapsButton: false,
    audioTrackButton: false,
    fullscreenToggle: true
  }

</script>


<style>
  .vjs-big-play-button {
    border: none !important;
    width: 50px !important;
    height: 50px !important;
    margin-top: -25px !important;
    margin-left: -25px !important;
    border-radius: 100% !important;
  }

  .vjs-icon-placeholder::before {
    top: 3px !important;
  }
</style>

<style lang="less" scoped>
.home {
  width: 100vw;
  background: url("@/assets/images/bg.png") repeat-y;
  background-size: cover;
  padding: 22px 35px 33px 35px;
  box-sizing: border-box;
  overflow-x: hidden;

  .title {
    .top-line {
      width: 100%;
      height: 1px;
      background-color: #000;
    }

    .center {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;

      .left {
        img {
          width: 46px;
        }
      }

      .right {
        img {
          width: 68px;
        }
      }
    }

    .bottom-line {
      width: 100%;
      height: 1px;
      background-color: #000;
    }
  }

  .community {
    margin-top: 15px;
    margin-bottom: 4px;
    font-size: 37px;

    .add {
      font-family: 'Times New Roman', Times, serif;
    }

    .unlock {
      font-size: 38px;
      margin-top: -10px;
      font-weight: 700;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .person {
    position: relative;
    width: 100%;
    height: 227px;

    .left {
      img {
        width: 174px;
      }
    }

    .center {
      position: absolute;
      top: -25px;
      left: 3px;
      img {
        width: 308px;
      }
    }

    .right {
      position: absolute;
      top: 35px;
      right: 32px;

      img {
        width: 45px;
      }
    }
  }

  .share {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -4px;
    z-index: 10;
    width: 100%;
    height: 352px;
    background: url("@/assets/images/border.png") no-repeat;
    background-size: cover;
    background-position: top center;
    .btn {
      margin-top: 24px;
      margin-bottom: 44px;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 163px;
        height: 53px;
        background: url(@/assets/images/btn.png) no-repeat;
        background-size: contain;
        .jd {
          width: 25px;
          margin-right: 5px;
        }
        span {
          font-size: 17px;
          color: #ebcc92;
          font-family: SourceHanSerifCN-Bold;
        }
      }
    }

    .ewm {
      width: 150px;
      img {
        width: 100%;
        background-color: purple;
      }
    }

    .text {
      margin-top: 34px;
      font-size: 17px;
      color: #76624f;
      letter-spacing: 2px;
    }
  }

  .videos {
    .video {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-top: 17px;
      padding: 10px 20px;
      width: 100%;
      height: 100px;
      background-color: #fffdf8;
      box-sizing: border-box;

      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: linhailishu;
        .food-name {
          font-size: 16px;
          font-weight: 700;
        }
        .food-desc {
          margin-top: 20px;
          font-size: 10px;
          .bottom {
            margin-top: 5px;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .center {
        position: absolute;
        right: 165px;
        img {
          width: 78px;
        }
      }

      .right {
        position: relative;
        width: 140px;
        height: 80px;
        background-color: #000;
        .video-player {
          width: 100%;
          height: 100%
        }
      }
    }
  }

  .company {
    margin-top: 20px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
  }
}
</style>
