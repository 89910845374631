import axios from "axios";

import { BASE_URL, TIMEOUT } from "./config";

class ZYRequest {
  constructor(baseURL, timeout) {
    this.instance = axios.create({
      baseURL,
      timeout,
    });

    this.instance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        config.headers.Authorization = "Bearer " + token;
        return config;
      },
      (err) => {
        throw new Error(err);
      }
    );
    this.instance.interceptors.response.use(
      (response) => {
        return response.data
      },
      (err) => {
        throw new Error(err);
      }
    );
  }
  request(config) {
    return new Promise((resolve, reject) => {
      this.instance(config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  get(config) {
    return this.request({ ...config, method: "GET" });
  }
  post(config) {
    return this.request({ ...config, method: "POST" });
  }
  put(config) {
    return this.request({ ...config, method: "PUT" });
  }
  patch(config) {
    return this.request({ ...config, method: "PATCH" });
  }
  delete(config) {
    return this.request({ ...config, method: "DELETE" });
  }
}

export default new ZYRequest(BASE_URL, TIMEOUT);
