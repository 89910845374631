<template>
  <div class="icp">
    <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2023002894号-5</a>
  </div>
</template>

<style lang="less" scoped>
  .icp {
    margin-top: 20px;
    text-align: center;
    a {
      text-decoration: none;
      color: #000;
    }
  }
</style>